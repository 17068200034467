import React from "react"

import Layout from "../components/templates/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Introuvable" />
    <h1>INTROUVABLE</h1>
    <p>Tu viens de prendre une route qui n&#39;existe pas... la tristesse.</p>
  </Layout>
)

export default NotFoundPage
